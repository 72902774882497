import PublicLayout from '../layouts/PublicLayout';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { getUserStreak } from '../api/streak';
import React from 'react';

export default function Streak() {
  const { data, error, isLoading } = useQuery({
    queryKey: ['user-streak'],
    queryFn: () => getUserStreak(localStorage.getItem('worlde_user_id')),
  });

  if (isLoading) {
    return (
      <PublicLayout>
        <Helmet>
          <title>Loading | DILEMMA</title>
        </Helmet>
        <div className="bg-lightGreen w-full h-full flex justify-center items-center">
          <div className="text-lg font-sans text-border">Loading...</div>
        </div>
      </PublicLayout>
    );
  }

  if (error) {
    return (
      <PublicLayout>
        <Helmet>
          <title>Error | DILEMMA</title>
        </Helmet>
        <div className="bg-lightGreen w-full h-full flex justify-center items-center">
          <div className="text-lg text-red-600 font-sans">
            An error occurred while fetching the personality traits. Please try
            again later.
          </div>
        </div>
      </PublicLayout>
    );
  }

  return (
    <PublicLayout>
      <Helmet>
        <title>Streak | DILEMMA</title>
      </Helmet>
      <div className="bg-lightGreen w-full h-full flex  items-center flex flex-col gap-16 overflow-auto">
        <div className="h-full flex flex-col justify-around">
          <div className="container font-sans text-border flex justify-center  my-40">
              <div className="w-fit grid grid-cols-8 gap-y-6 md:gap-y-12 items-center ">
                <p>Streaks: {data.streak}</p>
                <p>Total Counts: {data.count}</p>
              </div>            
          </div>
          <div className='pb-20'>
            <a
              href="/result"
              className="font-sans bg-white text-border text-2xl px-8 rounded-2xl py-1"
            >
              Back
            </a>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}

import { Helmet } from 'react-helmet';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getQuestionAndAnswer, setQuestionAndAnswer } from '../api/games';
import { useNavigate } from 'react-router-dom';
import WarningAlert from '../components/WarningAert';
import { useState } from 'react';
import Loading from '../components/Loading';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import PublicLayout from '../layouts/PublicLayout';
import { checkGameStatus } from '../api/home';

const Game = () => {
  const [mutationText, setMutationText] = useState('');
  const [isMutationError, setIsMutationError] = useState(false);

  const navigate = useNavigate();

  const {
    data: checkData,
    isError: isCheckError,
    isPending: isCheckingPending,
  } = useQuery({
    queryKey: ['check-status'],
    queryFn: () => checkGameStatus(localStorage.getItem('worlde_user_id')),
  });

  useEffect(() => {
    if (checkData) {
      if (!checkData.status) {
        navigate('/result');
      }
    }
  }, [navigate, checkData]);

  const { data, isError, isLoading } = useQuery({
    queryKey: ['questions-answers'],
    queryFn: () => getQuestionAndAnswer(),
  });
  const {
    mutate,
    isError: isMutationLoadError,
    isLoading: isMutationLoading,
  } = useMutation({
    mutationFn: ({ question_id, answer_id }) =>
      setQuestionAndAnswer({
        question_id,
        answer_id,
        user_id: localStorage.getItem('worlde_user_id'),
      }),
    onSuccess: () => {
      Cookies.set('user_played', 'true', { expires: 1 });
      navigate('/result');
    },
    onError: () => {
      setIsMutationError(true);
      setMutationText('An error occured. Please try again later');
    },
  });

  if (isLoading || isMutationLoading || isCheckingPending) {
    return (
      <div className="relative flex justify-center items-center w-full h-full bg-lightGreen">
        <Loading />
      </div>
    );
  }

  if (isError || isCheckError || isMutationLoadError) {
    return (
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <div className="text-lg text-red-600 font-sans px-10">
          An error occurred while fetching the game data. Please try again
          later.
        </div>
      </div>
    );
  }

  return (
    <PublicLayout>
      <Helmet>
        <title>DILEMMA</title>
      </Helmet>
      <div className="relative flex justify-center items-end w-full h-full bg-lightGreen">
        <div className="relative flex">
          <p
            className="font-sans text-border border-lightGreen rounded-md absolute text-md sm:text-xl lg:text-3xl bg-lightGreen -left-10 max-w-[120px] lg:max-w-[240px] top-[130px] sm:top-[60px] md:top-[0px] text-center cursor-pointer hover:border-border border-2 hover:text-darkGreen hover:bg-white transition duration-300 transform active:scale-95 active:shadow-md"
            onClick={() =>
              mutate({
                question_id: data?.id,
                answer_id: data?.answers[0].id,
              })
            }
          >
            {data?.answers[0].name}
          </p>
          <div
            className="font-sans text-border absolute rounded-md text-md sm:text-xl lg:text-3xl bg-lightGreen -right-10 max-w-[120px] lg:max-w-[240px]  top-[130px] sm:top-[60px] md:top-[0px] text-center cursor-pointer hover:border-border border-2 border-lightGreen hover:bg-white hover:text-darkGreen transition duration-500 transform active:scale-95 active:shadow-md"
            onClick={() =>
              mutate({
                question_id: data?.id,
                answer_id: data?.answers[1].id,
              })
            }
          >
            {data?.answers[1].name}
          </div>
          <div className="font-sans flex justify-center items-start text-darkGreen absolute bg-white bottom-12 text-lg lg:text-xl left-[40px] sm:left-[100px] md:left-[170px] h-[230px] md:h-[230px] w-[230px] md:w-[300px] text-center py-4 px-4 rounded-md overflow-y-auto">
            {data?.text}
          </div>
          <svg
            width="603"
            height="600"
            viewBox="0 0 603 600"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[280px] sm:w-[400px] md:w-[603px]"
          >
            <path d="M278 600V305" stroke="#604F22" strokeWidth="3" />
            <path d="M278 305L0.99999 55" stroke="#604F22" strokeWidth="3" />
            <path d="M321 241L49 2.00002" stroke="#604F22" strokeWidth="3" />
            <line
              x1="355.5"
              y1="599.985"
              x2="358.5"
              y2="304.985"
              stroke="#604F22"
              strokeWidth="3"
            />
            <line
              x1="358.924"
              y1="303.955"
              x2="601.924"
              y2="53.9545"
              stroke="#604F22"
              strokeWidth="3"
            />
            <line
              x1="319.942"
              y1="241.937"
              x2="537.942"
              y2="24.9369"
              stroke="#604F22"
              strokeWidth="3"
            />
          </svg>
        </div>
        <WarningAlert
          open={isMutationError}
          message={mutationText}
          onClose={() => setIsMutationError(false)}
        />
      </div>
    </PublicLayout>
  );
};

export default Game;

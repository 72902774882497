import { publicAPI } from "./axios";

export async function getPersonalityTraits(user_id) {
    try {
      const response = await publicAPI.get(`/personality-trait/?user_id=${user_id}`);
      return response.data;
    } catch (error) {
      throw new Error('An unexpected error occured');
    }
  }
  
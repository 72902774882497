import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import TElement from '../components/T-element';
import { useQuery } from '@tanstack/react-query';
import { getGameResult } from '../api/result';
import Loading from '../components/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout';
import { checkGameStatus } from '../api/home';

const Result = () => {
  const navigate = useNavigate();
  const {
    data: checkData,
    isError: isCheckError,
    isPending: isCheckingPending,
  } = useQuery({
    queryKey: ['check-status'],
    queryFn: () => checkGameStatus(localStorage.getItem('worlde_user_id')),
  });

  useEffect(() => {
    if (checkData) {
      if (checkData.status) {
        navigate('/');
      }
    }
  }, [navigate, checkData]);

  const { data, isError, isPending } = useQuery({
    queryKey: ['game-result'],
    queryFn: () => getGameResult(localStorage.getItem('worlde_user_id')),
  });
  if (isPending || isCheckingPending) {
    return (
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <Loading />
      </div>
    );
  }
  if (isError || isCheckError) {
    return (
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <div className="text-lg text-red-600 font-sans px-10">
          An error occurred while fetching the result data. Please try
          again later.
        </div>
      </div>
    );
  }
  return (
    <PublicLayout>
      <Helmet>
        <title>Result | DILEMMA</title>
      </Helmet>
      <div className="bg-lightGreen w-full h-full flex justify-center items-center overflow-auto">
        <p className="fixed top-4 md:right-8 right-6 lg:right-12 xl:right-20 font-sans text-border text-sm md:text-base lg:text-lg xl:text-xl">
          Streaks:&nbsp;&nbsp;{data.streak}
        </p>
        <p className="fixed top-10 lg:top-14 right-6 md:right-8 lg:right-12 xl:right-20 font-sans text-border text-sm md:text-base lg:text-lg xl:text-xl">
          Total:&nbsp;&nbsp;{data.count}
        </p>
        <div className="container flex justify-center items-center flex flex-col gap-16">
          <div className="flex gap-6 md:gap-32 mt-10">
            <div className="flex justify-center flex-col items-center gap-4 md:min-w-[320px] max-w-[320px]">
              <TElement
                percent={
                  (100 * data.answers[0].count) /
                  (data.answers[0].count + data.answers[1].count)
                }
              />
              <p className="text-2xl md:text-4xl lg:text-5xl text-border font-number italic font-medium">
                {(
                  (100 * data.answers[0].count) /
                  (data.answers[0].count + data.answers[1].count)
                ).toFixed(0)}
                %
              </p>
              <p
                className={classNames(
                  'capitalize text-border text-lg md:text-2xl lg:text-3xl font-sans tracking-widest',
                  { 'text-white underline': data?.answers[0].status }
                )}
              >
                {data?.answers[0].personality}
              </p>
              <p className="capitalize text-borer text-sm md:text-xl lg:text-2xl text-border text-center">
                {data?.answers[0].name}
              </p>
            </div>
            <div className="flex justify-center flex-col items-center gap-4 md:min-w-[320px] max-w-[320px]">
              <TElement
                percent={
                  (100 * data.answers[1].count) /
                  (data.answers[0].count + data.answers[1].count)
                }
              />
              <p className="text-2xl md:text-4xl lg:text-5xl text-border font-number italic font-medium">
                {(
                  (100 * data.answers[1].count) /
                  (data.answers[0].count + data.answers[1].count)
                ).toFixed(0)}
                %
              </p>
              <p
                className={classNames(
                  'capitalize text-border text-lg md:text-2xl lg:text-3xl font-sans tracking-widest',
                  { 'text-white underline': data?.answers[1].status }
                )}
              >
                {data?.answers[1].personality}
              </p>
              <p className="capitalize text-borer text-sm md:text-xl lg:text-2xl text-border text-center">
                {data?.answers[1].name}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full px-4 sm:px-8 md:px-16 lg:px-24 xl:px-40">
            {/* <a
              href="/streak"
              className="px-4 sm:px-8 py-2 bg-white rounded-2xl hover:opacity-80 font-sans text-border text-sm sm:text-base md:text-xl lg:text-2xl"
            >
              Streak
            </a> */}
            <p className="lg:w-28"></p>
            <p className="font-sans text-border text-xl md:text-3xl lg:text-4xl text-center">
              Does the poll seem
              <br /> off? Come back <br /> and check in later!
            </p>

            <button
              className="px-4 sm:px-8 py-2 bg-white rounded-2xl hover:opacity-80 font-sans text-border text-sm sm:text-base md:text-xl lg:text-2xl"
              onClick={() => navigate('/personality-trait')}
            >
              Library
            </button>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

export default Result;

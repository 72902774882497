import PublicLayout from '../layouts/PublicLayout';
import { Helmet } from 'react-helmet';
import { useQuery } from '@tanstack/react-query';
import { getPersonalityTraits } from '../api/personality-traits';
import Loading from '../components/Loading';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PersonalityTrait() {
  const navigate = useNavigate()
  const { data, error, isLoading } = useQuery({
    queryKey: ['personality-traits'],
    queryFn: () => getPersonalityTraits(localStorage.getItem('worlde_user_id')),
  });

  if (isLoading) {
    return (
      <PublicLayout>
        <Helmet>
          <title>Loading | DILEMMA</title>
        </Helmet>
        <div className="bg-lightGreen w-full h-full flex justify-center items-center">
          <Loading />
        </div>
      </PublicLayout>
    );
  }

  if (error) {
    return (
      <PublicLayout>
        <Helmet>
          <title>Error | DILEMMA</title>
        </Helmet>
        <div className="bg-lightGreen w-full h-full flex justify-center items-center">
          <div className="text-lg text-red-600 font-sans px-10">
            An error occurred while fetching the personality traits. Please try
            again later.
          </div>
        </div>
      </PublicLayout>
    );
  }

  return (
    <PublicLayout>
      <Helmet>
        <title>Personality Trait | DILEMMA</title>
      </Helmet>
      <div className="bg-lightGreen w-full h-full flex  items-center flex flex-col gap-16 overflow-auto">
        <div className="h-full flex flex-col justify-around">
          <div className="container font-sans text-border flex justify-center  my-40">
            {data && (
              <div className="w-fit grid grid-cols-8 gap-y-6 md:gap-y-12 items-center ">
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <p className="text-sm sm:text-base md:text-2xl lg:text-3xl xl:text-4xl col-span-3 sm:col-span-2 pl-2">
                      {item.name}
                    </p>
                    <p className="text-sm sm:text-base md:text-2xl lg:text-3xl xl:text-4xl text-center">
                      {item.user_count}x
                    </p>
                    <svg
                      width="190"
                      height="34"
                      viewBox="0 0 190 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="px-2 col-span-1 sm:col-span-2 w-12 sm:w-20 md:w-36 lg:w-44 xl:w-[190px] text-right pr-4"
                    >
                      <path
                        d="M186 17L8.76188e-06 17"
                        stroke="#604F22"
                        strokeWidth="3"
                      />
                      <path
                        d="M189 17L168 2"
                        stroke="#604F22"
                        strokeWidth="3"
                      />
                      <path
                        d="M189 16L168 32"
                        stroke="#604F22"
                        strokeWidth="3"
                      />
                    </svg>
                    <p className="text-xs sm:text-base md:text-md lg:text-lg xl:text-xl col-span-3">
                      {item.description}
                    </p>
                  </React.Fragment>
                ))}
              </div>
            )}
            
          </div>
          <div className='pb-20'>
            <button
              className="font-sans bg-white text-border text-2xl px-8 rounded-2xl py-1"
              onClick={() => navigate('/result')}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
}
